import { useHistory } from "react-router-dom";
import {
    CardContent,
    CardImg,
    CardBody,
    CardTitle,
    CardDivider,
    CardTechStack
} from "components/ProjectFields";
import { projectData } from "appData";

interface CardProps {
    data: typeof projectData[0]
    voffset: string;
}


const ProjectCard: React.FC<CardProps> = (
    props
) => {
    let history = useHistory();
    const { voffset, data } = props
    const { title, techstack, imgurl, href } = data
    const img = require(`../${imgurl}`).default
    const handleBtnClicked = () => {
        history.push({ "pathname": href })
    }
    return (
        <CardContent voffset={voffset} onClick={handleBtnClicked} >
            <CardImg variant="top" src={img} />
            <CardBody>
                <CardTitle>{title}</CardTitle>
                <CardDivider />
                <CardTechStack> {techstack} </CardTechStack>
            </CardBody>
        </CardContent >
    )
}

export default ProjectCard