import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR, BLACK_COLOR, WHITE_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "appconstants";
import Image from "react-bootstrap/Image";
import BSButton from "react-bootstrap/Button";

const AboutMeContent = styled.section`
  padding-top: 10rem;
  padding-bottom: 9rem;
`;

const AboutMeDescContent = styled.section`
  padding-left: 0rem;
  padding-right: 0rem;
`;

const AboutMeTitle = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: 48px;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
`;

const AboutMeDescription = styled.h3`
  margin-top: 25px;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  color: ${SECONDARY_COLOR};
  line-height: 2;
`;

const AboutMeImage = styled(Image)`
`;

const ResumeTitle = styled.h1`
  text-align: center;
  font-family: ${PRIMARY_FONT};
  font-size: 36px;
  font-weight: bold;
  color: ${WHITE_COLOR};
  margin-bottom: 2rem;
`;
const ResumeButton = styled(BSButton)`
  margin-top: 15px;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  font-weight: 700;
  color: ${PRIMARY_COLOR};
  text-align: center;
  padding: 10px 28px;
  border-radius: 0;
  border-color: ${PRIMARY_COLOR};
  background-color: transparent;
  &:hover{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: ${BLACK_COLOR} !important;
  }
  &:focus{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: transparent !important;
    color: ${PRIMARY_COLOR} !important;
  }
  &:active{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: black !important;
  }
`;

export {
    AboutMeContent,
    AboutMeDescContent,
    AboutMeTitle,
    AboutMeDescription,
    AboutMeImage,
    ResumeTitle,
    ResumeButton
}