import styled from "styled-components";
import BSNavBar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Scroll from 'react-scroll';
import { useHistory } from "react-router-dom";
import { PRIMARY_FONT, BLACK_COLOR, HEADER_TEXT_COLOR, SECONDARY_COLOR } from "appconstants";


const Navbar = styled(BSNavBar)`
background-color: ${BLACK_COLOR};
opacity: .90;
font-size: 20px;
font-family: ${PRIMARY_FONT};
border-bottom: 1px solid ${SECONDARY_COLOR};
`

const NavLink: React.FC<{target: string, href: string}> = (props) => {
  const { target, href } = props;
  let history = useHistory();
  return (
    <Scroll.Link onClick={() => history.push(href)} className="nav-link"
     style={{color: HEADER_TEXT_COLOR}} to={target} spy={true} smooth={true} offset={-70} activeClass="active">
        {props.children}
    </Scroll.Link>
  )
}

const Header = () => (
    <Navbar id="navbar" className="inactive" color={HEADER_TEXT_COLOR} collapseOnSelect expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="/" style={{ color: HEADER_TEXT_COLOR, fontSize: "30px", fontFamily: PRIMARY_FONT }}>KC</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <NavLink target='home' href="/#home">Home</NavLink>
            <NavLink target="projects" href="/#projects">Projects</NavLink>
            <NavLink target="about" href="/about">About</NavLink>
            <NavLink target="contact" href="/contact">Contact</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
)

export default Header