import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { projectData } from "appData";
import ProjectBanner from "components/ProjectBanner";
import ProjectCard from "components/ProjectCard";
import { MainTitle, MainContent, MainSubTitle, MainDescription, MainDivider } from "../components/HomeFields";
import { ProjectDownArrow, ProjectContent, ProjectMainTitle, CardTrapezium } from "components/ProjectFields";

const projectDataToBanners = (data: typeof projectData) => {
    let boolInvert = true;
    return (
        data.map((data) => {
            boolInvert = !boolInvert
            return (
                <ProjectBanner key={data.title} data={data} invert={boolInvert} />
            )
        }
        )
    )
}

const projectDataToCards = (cardData: typeof projectData) => {
    return (
            <CardTrapezium>
                <Container>
                    <Row>
                        <Col>
                            <ProjectCard voffset={"0rem"} data={cardData[0]} />
                        </Col>
                        <Col>
                            <ProjectCard voffset={"2.5rem"} data={cardData[1]} />
                        </Col>
                        <Col>
                            <ProjectCard voffset={"5rem"} data={cardData[2]} />
                        </Col>
                    </Row>
                </Container>
            </CardTrapezium>
    )
}

const Home: React.FC = () =>
(
    <React.Fragment>
        <MainContent id="home">
            <Container>
                <Row>
                    <Col xs={1}></Col>
                    <Col><MainTitle>Hi, I'm Kin Chang.</MainTitle></Col>
                    <Col xs={1}></Col>
                </Row>
                <Row>
                    <Col xs={1}></Col>
                    <Col><MainSubTitle>I'm a Machine Learning and Software Engineer.</MainSubTitle></Col>
                    <Col xs={1}></Col>
                </Row>
                <Row>
                    <Col xs={1}></Col>
                    <Col><MainDivider /></Col>
                    <Col xs={1}></Col>
                </Row>
                <Row>
                    <Col xs={2}></Col>
                    <Col><MainDescription>Modern development in AI has inspired me to become a member of the revolution. I like to learn about new technology and solve problems with Engineering solutions.</MainDescription></Col>
                    <Col xs={2}></Col>
                </Row>
            </Container>
        </MainContent>
        <ProjectContent id="projects">
            {/* <Row>
                <Col className="text-center"><ProjectMainTitle>Projects</ProjectMainTitle></Col>
            </Row> */}
            {/* <Row>
                <Col className="text-center" style={{marginTop: "1rem"}}><ProjectDownArrow /></Col>
            </Row> */}
            {projectDataToBanners(projectData.slice(0, -3))}
            {projectDataToCards(projectData.slice(-3))}
        </ProjectContent>

    </React.Fragment>
);

export default Home