import React, { FunctionComponent } from "react";
import styled from "styled-components";
import backgroundImg from "../assets/starryBackground.jpeg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BLACK_COLOR, SECONDARY_COLOR } from "appconstants";

const Background = React.memo(styled.section`
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background: url("${backgroundImg}") no-repeat center center fixed;
  background-color: rgb(12, 12, 12, 0.7);
  background-blend-mode: overlay;
  // opacity: .4;
`);


const DefaultLayout: FunctionComponent = React.memo(props => {
  return (
    <>
      <Background>
        <Header />
        <main>{props.children}</main>
        <Footer />
      </Background>
    </>
  );
});

export default DefaultLayout;
