const projectData = [
    {
        "title": "eTraining",
        "subtitle": "Workflow Facilitation and Archive",
        "date": "Aug 2018 - present",
        "imgurl": "assets/projects/eTraining.jpg",
        "techstack": "Node / React / Mongodb / Mongoose / Material UI",
        "shortDescription": "A MERN project I started to facilitate my daily workflow, while storing all essential information in the backend database for easy access and physical archiving.",
        "longDescription": "I started this MERN project on my own while working at Sony Inc. as an Engineering Support Specialist. The vision was to facilitate my daily workflow, while storing all essential information in backend database for easy access and physical archiving.\n Applying my React knowledge learnt from Udemmy, I was able to create a simple yet elegant frontend user interface using ReactJS and MaterialUI. I built backend RESTful API using NodeJS and ExpressJS, while utilizing data storage with MongoDB through Mongoose. \n While this app is still in development, it already showed great promise that allowed me to adapt to a more clear and organized workflow at Sony.\n Check out key functionalities of the app in the live website below!",
        "href": "/project/1",
        "buttons": [
            {
                "text": "VISIT LIVE",
                "url": "https://etrain.kinchang.com"
            }
        ]
    },
    {
        "title": "JamBand",
        "subtitle": "Human-Robot Interactive Band",
        "date": "Jan - March 2018",
        "imgurl": "assets/projects/JamBand.jpg",
        "vidurl": "https://www.youtube.com/embed/FkhUbZ_ER8M",
        "techstack": "Arduino / HTML / IoT / ESP8266 / RevealJs",
        "shortDescription": "Two instruments that play in harmony. Change the tempo or songs with your hands! We built them with ESP controllers, and incorporated sensors and actuators.",
        "longDescription": "This is a video demo of one of the projects carried out in my final year cap-stone course. The goal was to build two instruments using ESP controllers, incorporating sensors and actuators.\n One big challenge was getting the two controllers to sync up so that the two instruments can play in harmony. We experimented with some wireless options but ended up with a Master/Slave Serial setup. The Slave waits inside the Arduino Loop, until the Master gives commands using Serial Communication (wired). The end result is sublime as the drumstick and buzzer are in harmony at every beat / key.\n As a side note, I was exploring Web Development at the time and came across Reveal.js, an HTML Presentation framework; and it's awesome! A step-by-step guide was thus created for this project. Check it out down below!",
        "href": "/project/2",
        "buttons": [
            {
                "text": "VISIT LIVE",
                "url": "https://ckwojai.github.io/EE183_JamBand/index.html"
            },
            {
                "text": "GUIDE",
                "url": "https://ckwojai.github.io/EE183_JamBand/documentation/documentation.html#/sec-title-slide"
            },
            {
                "text": "GITHUB",
                "url": "https://github.com/ckwojai/EE183_JamBand"
            }
        ]
    },
    {
        "title": "Quadcopter",
        "subtitle": "Hovering Algorithm with PID Controller",
        "date": "Sept 2017 - June 2018",
        "imgurl": "assets/projects/Quadcopter.jpg",
        "techstack": "Arduino / Autodesk EAGLE / PCB Design",
        "shortDescription": "I successfully led a 4-man team to create a hovering quadcopter through wireless communication with radio modules, sensor fusion with IMU, as well as circuit and PCB design.",
        "longDescription": "This was a year-long project with IEEE during my final year at UCLA. The ultimate goal was to build a quadcopter from scratch. Being the leader of a 4-man team, I was able to guide my team through difficult time, achieve milestones along the way, and deliver a successful product (quadcopter that hovers) as a result.\n This experience is truly rewarding. I had hands on experience on\n 1) Wireless communication using Radio Modules\n 2) Sensor Fusion with IMU (Gyro and Accel)\n 3) Circuit and PCB Design with Eagle\n 4) Implementing Hovering Algorithm with PID controller.\n This project taught me to become a better team player that follows, leads, and motivates: how to carry an Engineering project from start to finish.\n All code is hosted on Github.",
        "href": "/project/3",
        "buttons": [
            {
                "text": "GITHUB",
                "url": "https://github.com/ckwojai/IEEE-Advanced-Project"
            },
            {
                "text": "UCLA IEEE",
                "url": "http://ieeebruins.com/projects"
            }
        ]
    },
    {
        "title": "2WheelCar",
        "subtitle": "State Estimation and Motion Planning",
        "date": "Jan - March 2018",
        "imgurl": "assets/projects/2WheelCar.jpg",
        "vidurl": "https://www.youtube.com/embed/30bCeLLuHEQ",
        "techstack": "Arduino / MatLab / IoT / ESP8266",
        "shortDescription": "By deriving the state-to-mesasurement functions and state estimation using Kalman Filter, we built a two-wheel car that can parallel park.",
        "longDescription": "Last project in my final year cap-stone course. Appling the knowledge of State Estimation and Motion Planning from lectures, we built a two-wheel car able to parallel park.\n One challenge is deriving the state-to-measurement function and state estimation. By attending office hours and learning from MIT lectures, we derived the eqauation for 8 cases, linearize it at each state evolution, and applied Kalmen filter for accurae state estimation.\n Another challenge is to find a way to show accuracy of our State Estimation and Motion Planning algorithm. For thiis, a wireless communication between Matlab and the car was developed. State Estimation was handled by Matlab in our PC, which is connected to the car's local WIFI network. An HTTP get request is sent from the PC to the car as control input. After the car moves, new sensor measurment is sent back in Json to Matlab for the next Kalmen update. The cycle then continues.\n As a result, Matlab plotted our state estimation in a live, continuous manner as the actual car moves; clearly demonstrating the accuracy of our system.",
        "href": "/project/4",
        "buttons": [
            {
                "text": "GITHUB",
                "url": "https://github.com/ckwojai/2wheelcar"
            },
            {
                "text": "DOCUMENTATION",
                "url": "https://github.com/ckwojai/2wheelcar/blob/master/Documentation/2wheelcar.pdf"
            }
        ]
    },
    {
        "title": "Masscopter",
        "subtitle": "Robotics System Design",
        "date": "March - June 2018",
        "imgurl": "assets/projects/Masscopter.jpg",
        "techstack": "Arduino / MatLab / Quadcopter Dynamics / Control Theory",
        "shortDescription": "Quadcopter with a twist, literally, using our underactuated spinning controller",
        "longDescription": "This is the final research project in my capstone course. We aimed to design an off-center spinning mass underactuated controller to steer flying objects. A quadcopter with a rotating arm attached is used to demonstrate the principle of such a controller. To approach this control problem, we first derived the Mathematical Model, followed by simulation and motor control, and finally execute the actual implementation in Crazyflie by Bitcraze.\n Our Paper, Presentation and Code are all hosted on Github below.",
        "href": "/project/5",
        "buttons": [
            {
                "text": "PRESENTATION",
                "url": "https://github.com/Amir-Omidfar/183DB-/blob/master/final_presentation.pdf"
            },
            {
                "text": "GITHUB",
                "url": "https://github.com/Amir-Omidfar/183DB-"
            },
            {
                "text": "PAPER",
                "url": "https://github.com/Amir-Omidfar/183DB-/blob/master/final_paper.pdf"
            }
        ]
    },
    {
        "title": "Boardkey",
        "subtitle": "CS M117 Computer Network",
        "date": "March - June 2018",
        "imgurl": "assets/projects/Boardkey.jpg",
        "techstack": "Swift / Bluetooth",
        "shortDescription": "Broken Keyboard? Don't worry Boardkey is here to help. Just pull out your phone and use it as keyboard.",
        "longDescription": "This is a project-based course focusing on wireless technology. We created an IOS application that allows an iPhone to be used as a wireless Bluetooth keyboard for Mac Computers. To make this possible, we familiarized ourselves with Swift and utilized two Apple frameworks: Core Bluetooth and Core Graphics.\n Our Report, Presentation and Code are all hosted on Github below.",
        "href": "/project/6",
        "buttons": [
            {
                "text": "GITHUB",
                "url": "https://github.com/ckwojai/BoardKey"
            },
            {
                "text": "PRESENTATION",
                "url": "https://github.com/ckwojai/BoardKey/blob/master/Documents/final_presentation.pdf"
            },
            {
                "text": "REPORT",
                "url": "https://github.com/ckwojai/BoardKey/blob/master/Documents/final_report.pdf"
            }
        ]
    },
    {
        "title": "YelpCamp",
        "subtitle": "Udemy Web Bootcamp by Colt Steele",
        "date": "July - Sept., 2017",
        "imgurl": "assets/projects/Yelpcamp.jpg",
        "techstack": "Node / Mongodb / ExpressJS / BootstrapJS / PassportJS",
        "shortDescription": "First Project in Udemmy. Display campgrounds with user authorization and authentication",
        "longDescription": "This is the last project in an Udemmy course called the Web Developer Bootcamp, by Colt Steele.\n To build this site, various technologies in Web Development are utilized, including Frontend: Templating using EJS, Layout using Bootstrap and Jquery; Backend: RESTful routing with Express, Database management with Mongodb and Mongoose, and User Authentication with Passport\n Try it out yourself below!",
        "href": "/project/7",
        "buttons": [
            {
                "text": "VISIT LIVE",
                "url": "https://yelpcamp.kinchang.com/"
            }
        ]
    }
]

const personalData = {
    "introduction": "I am  a new graduate from UCLA (Class of 2018) with a Bachelor Degree in Electrical Engineering. My undergraduate studies have exposed me to extensive experience in Machine Learning, Robotic System, and C Programming. I am pursuing a career in Machine Learning with EV / Software Development. For more information, view my resumes below.",
    "iconurl": "assets/personalIcon.jpg"
}

export {
    projectData,
    personalData
}