import React from "react";
import { projectData } from "appData";
import {
    PageContent,
    PageTitle,
    PageSubtitle,
    PageTechStack,
    PageDescription,
    PageImage,
    PageParagraph,
    PageDate,
    PageButton,
    PageDivider,
    PageShortDescription,
    ProjectLink
} from "../components/ProjectFields";
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return null;
}


const Project: React.FC = (props) => {
    let { id } = useParams<{id: string}>();
    const isFirstProject = Number(id) == 1;
    const isLastProject = Number(id) == 7;
    const { title, subtitle, longDescription, shortDescription, imgurl, date, buttons, vidurl, techstack } = projectData[Number(id)-1]
    const img = require(`../${imgurl}`).default
    const paragraphs = longDescription.split("\n")
    return (
        <PageContent>
            <ScrollToTopOnMount />
            <Container>
                <Row>
                    <Col xs={2} />
                    <Col>
                        <Row>
                            <Col>
                                <PageTitle>{title}</PageTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageSubtitle>{subtitle} </PageSubtitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageTechStack>{techstack}</PageTechStack>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "2rem"}}>
                            <Col>
                                {
                                    vidurl ? (
                                        <ResponsiveEmbed aspectRatio="16by9">
                                            <iframe allowFullScreen src={vidurl}></iframe>
                                        </ResponsiveEmbed>) :
                                        (<PageImage src={img} fluid />)
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageDate> {date} </PageDate>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageShortDescription>{shortDescription}</PageShortDescription>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {buttons.map((btnInfo, index) => {
                                    if (index == 0) {
                                    return (
                                        <Row>
                                            <Col className="d-flex">
                                                <a href={btnInfo.url} target="_blank">
                                                    <PageButton>{btnInfo.text}
                                                    </PageButton>
                                                </a>
                                            </Col>
                                        </Row>
                                    )
                                    }
                                }
                                )}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "3rem" }}>
                            <Col>
                                <PageDivider />
                                <PageDescription>
                                    {paragraphs.map((p) => <PageParagraph key={p}>{p}</PageParagraph>)}
                                </PageDescription>

                            </Col>
                        </Row>
                        <Row style={{ marginTop: "5rem" }}>
                            {(!isFirstProject) ?
                                <Col style={{ textAlign: "left" }}>
                                    <ProjectLink next={false} to={"/project/" + String(Number(id) - 1)}>
                                        Previous Project
                                    </ProjectLink>
                                </Col> : ""}
                            {(!isLastProject) ?
                                <Col style={{ textAlign: "right" }}>
                                    <ProjectLink next to={"/project/" + String(Number(id) + 1)}>
                                        Next Project
                                    </ProjectLink>
                                </Col> : ""}
                        </Row>
                    </Col>
                    <Col xs={2} />
                </Row>
            </Container>
        </PageContent>
    )
};

export default Project