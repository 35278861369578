import styled from "styled-components";
import { WHITE_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "appconstants";
import React from "react";

const MainContent = React.memo(styled.section`
  padding-top: 16rem;
  padding-bottom: 14rem;
`);

const MainTitle = React.memo(styled.h1`
  text-align: center;
  font-family: ${PRIMARY_FONT};
  font-size: 96px;
  color: ${WHITE_COLOR};
`);

const MainSubTitle = React.memo(styled.h2`
  text-align: center;
  margin-top: 15px;
  font-family: ${SECONDARY_FONT};
  font-size: 36px;
  color: ${WHITE_COLOR};
`);

const MainDescription = React.memo(styled.h3`
  text-align: center;
  margin-top: 15px;
  font-family: ${SECONDARY_FONT};
  font-size: 24px;
  color: ${WHITE_COLOR};
`);

const MainDivider = React.memo(styled.hr`
  border-top: 1px solid ${WHITE_COLOR};
`);

export {
    MainContent,
    MainTitle,
    MainSubTitle,
    MainDescription,
    MainDivider
}
