import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GithubSvg from "../assets/footer/github.svg";
import LinkedInSvg from "../assets/footer/linkedin.svg";
import FacebookSvg from "../assets/footer/facebook.svg";
import { SECONDARY_COLOR, SECONDARY_FONT } from "appconstants";

const StyledFooter = styled.footer`
background-color: #191919;
opacity: .90;
color: white;
height: 4rem;
line-height: 4rem;
font-family: ${SECONDARY_FONT};
font-size: 16px;
border-top: 1px solid ${SECONDARY_COLOR};
`

const StyledAnchor = styled.a`
color: ${SECONDARY_COLOR}
`

const SocialMediaSvgs:React.FC = () => {
  return (
    <div >
      <a href="https://www.linkedin.com/in/kinchang/"><img src={LinkedInSvg} alt="LinkedIn" /></a>
      <a style={{marginLeft: "50px"}} href="https://github.com/ckwojai"><img src={GithubSvg} alt="Github" /></a>
      <a style={{marginLeft: "50px"}} href="https://www.facebook.com/wilson.chang.3979"><img src={FacebookSvg} alt="Facebook" /></a>
    </div >
  )
}
// const SvgBtns:React.FC = () => {
//   re
// }

const Footer = () => (
  <StyledFooter>
    <Container>
      <Row>
        <Col>
          © 2021 Kin Chang | Web design by <StyledAnchor href="https://katherineshan.com">Nightlark</StyledAnchor>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <SocialMediaSvgs />
        </Col>
      </Row>
    </Container>
  </StyledFooter>
)

export default Footer