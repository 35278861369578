import {
    BannerTitle,
    BannerTrapezium,
    BannerDescContent,
    BannerDescBox,
    BannerSubTitle,
    BannerDate,
    BannerTechStack,
    BannerDescription,
    BannerDivider,
    BannerButton,
    BannerImage,
} from "components/ProjectFields";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { projectData } from "appData";

interface BannerProps {
    data: typeof projectData[0]
    invert: boolean
}

const ProjectBanner: React.FC<BannerProps> = (
    props
) => {
    const { invert, data } = props
    const { title, subtitle, techstack, imgurl, shortDescription, href, date } = data
    const img = require(`../${imgurl}`).default
    const history = useHistory();
    const handleBtnClicked = () => {
        history.push({ "pathname": href })
    }
    return (
        <BannerTrapezium invert={invert}>
            <Container>
                <Row className="align-items-center">
                    <Col xs={6}>
                        <BannerImage src={img} rounded fluid />
                    </Col>
                    <Col>
                        <BannerDescContent>
                            <BannerTitle>{title}</BannerTitle>
                            <BannerTechStack>{techstack}</BannerTechStack>
                            <BannerDescBox>
                                <BannerSubTitle>{subtitle}</BannerSubTitle>
                                {/* <BannerDate>{date}</BannerDate> */}
                                <BannerDivider />
                                <BannerDescription>{shortDescription}</BannerDescription>
                            </BannerDescBox>
                        <BannerButton onClick={handleBtnClicked}>VIEW PROJECT</BannerButton>
                        </BannerDescContent>
                    </Col>
                </Row>
            </Container>
        </BannerTrapezium>
    )
}

export default ProjectBanner