import styled from "styled-components";
import { BLACK_COLOR, GRAY_COLOR, WHITE_COLOR, PRIMARY_FONT, SECONDARY_FONT, PRIMARY_COLOR, SECONDARY_COLOR } from "appconstants";
import Image from "react-bootstrap/Image";
import BSCard from "react-bootstrap/Card";
import BSButton from "react-bootstrap/Button";
import { ReactComponent as DownArrow } from "assets/projects/downArrow.svg";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const ProjectContent = styled.section`
  // background-color: ${BLACK_COLOR};
`;

const ProjectMainTitle = styled.span`
padding-top: 3px;
padding-bottom: 5px;
padding-left: 3rem;
padding-right: 3rem;
border-top: 1px solid ${SECONDARY_COLOR};
border-bottom: 1px solid ${SECONDARY_COLOR};
// background-color: ${BLACK_COLOR};
font-family: ${PRIMARY_FONT};
font-size: 48px;
font-weight: 600;
color: ${SECONDARY_COLOR};
text-align: center;
`

const ProjectDownArrow = DownArrow

/*
================================================================================
**************************  Banner Fields  *************************************
================================================================================
*/

const BannerTrapezium = styled.div<{invert: boolean}>`
clip-path: ${props => props.invert ? "unsert":"polygon(0% 0%, 100% 6%, 100% 94%, 0% 100%)"};
padding-top: 9rem;
padding-bottom: 9rem;
// background-color: ${props => props.invert ?"transparent":GRAY_COLOR};
background-color: transparent;
`;

const BannerDescContent = styled.section`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const BannerTitle = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: 36px;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
`;

const BannerSubTitle = styled.h2`
  margin-top: 10px;
  font-family: ${PRIMARY_FONT};
  font-size: 20px;
  font-weight: 600;
  color: ${WHITE_COLOR};
`;

const BannerDate = styled.p`
  font-family: ${PRIMARY_FONT};
  font-weight: 400;
  display:inline;
  color: ${SECONDARY_COLOR};
  font-size: 14px;
`

const BannerTechStack = styled.h3`
  margin-top: 1px;
  font-family: ${PRIMARY_FONT};
  font-size: 16px;
  color: ${SECONDARY_COLOR};
`;

const BannerDescription = styled.h3`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  color: ${SECONDARY_COLOR};
  line-height: 2;
`;

const BannerDescBox = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 12px 12px 12px 12px;
  background: rgba(132, 214, 209, 0.1);
`

const BannerDivider = styled.hr`
  margin-top: 10px;
  border-top: 1px solid ${SECONDARY_COLOR};
`;

const BannerImage = styled(Image)`
`;

const BannerButton = styled(BSButton)`
  margin-top: 15px;
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 700;
  color: ${PRIMARY_COLOR};
  padding: 10px 25px;
  border-radius: 0;
  border-color: ${PRIMARY_COLOR};
  background-color: transparent;
  &:hover{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: ${BLACK_COLOR} !important;
  }
  &:focus{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: transparent !important;
    color: ${PRIMARY_COLOR} !important;
    box-shadow: 0 0 0 0.2rem ${GRAY_COLOR};
  }
  &:active{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: black !important;
  }
`;
/*
================================================================================
**************************  Card Fields  *************************************
================================================================================
*/

const CardTrapezium = styled.div`
clip-path: polygon(0% 0%, 100% 6%, 100% 100%, 0% 100%);
padding-top: 9rem;
padding-bottom: 9rem;
// background-color: ${GRAY_COLOR}
background-color: transparent;
`;

const CardContent = styled(BSCard) <{ voffset: string }>`
margin-top: ${(props) => props.voffset};
padding-left: 1rem;
padding-right: 1rem;
background-color: transparent;
// border-color: ${WHITE_COLOR};
border-color: transparent;
border-width: thin;
  &:hover{
    // border-color: ${BLACK_COLOR} !important;
    // background-color: ${BLACK_COLOR} !important;
    color: ${BLACK_COLOR} !important;
  }
`;

const CardImg = styled(BSCard.Img)`
`;

const CardBody = styled(BSCard.Body)`
padding: 0 0 0 0;
margin-top: 0.5rem;
padding: 12px 12px 12px 12px;
background: rgba(132, 214, 209, 0.1);
`;

const CardTitle = styled(BSCard.Title)`
font-family: ${PRIMARY_FONT};
font-size: 32px;
font-weight: 700;
color: ${PRIMARY_COLOR};
text-align: left;
`;

const CardTechStack = styled(BSCard.Subtitle)`
font-family: ${PRIMARY_FONT};
font-size: 14px;
font-weight: 400;
color: ${SECONDARY_COLOR};
text-align: left;
`;

const CardDivider = styled.hr`
  border-top: 1px solid ${SECONDARY_COLOR};
  margin-top: 0.7rem;
  margin-bottom: 1rem;
`;

const CardSubtitle = styled(BSCard.Text)`
font-family: ${PRIMARY_FONT};
font-size: 16px;
font-weight: 700;
color: ${WHITE_COLOR};
text-align: left;
margin-top: 0.5rem;
margin-bottom: 1rem !important;
`

/*
================================================================================
**************************  Page Fields  *************************************
================================================================================
*/
const PageContent = styled.section`
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: transparent;
`;

const PageImage = styled(Image)`
margin: auto;
max-height: 364px;
`;

const PageTitle = styled.h1`
font-family: ${PRIMARY_FONT};
font-size: 48px;
font-weight: bold;
color: ${PRIMARY_COLOR};
text-align: left;
`;

const PageSubtitle = styled.h2`
font-family: ${PRIMARY_FONT};
font-size: 26px;
color: ${WHITE_COLOR};
text-align: left;
font-weight: 700;
`;

const PageTechStack = styled.h2`
font-family: ${PRIMARY_FONT};
font-size: 16px;
color: ${SECONDARY_COLOR};
text-align: left;
font-weight: 400;
`;

const PageDate = styled.p`
  font-weight: 400;
  display:inline;
  font-size: 16px;
  font-family: ${PRIMARY_FONT};
  color: ${SECONDARY_COLOR};
`

const PageParagraph = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  margin-top: 2rem;
  color: ${SECONDARY_COLOR};
  
  margin-bottom: 0;
`

const PageDescBox = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 20px 20px;
  background: rgba(132, 214, 209, 0.1);
  border: 1px solid ${SECONDARY_COLOR};
`
const PageDescription = styled.div`
  margin-top: 25px;
`;

const PageShortDescription = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 30px;
  margin-top: 2rem;
  color: ${WHITE_COLOR};
`;


const StyledPageButton = styled(BSButton)`
  margin-top: 15px;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  font-weight: 700;
  color: ${PRIMARY_COLOR};
  text-align: center;
  padding: 10px 10px 10px 28px;
  border-radius: 0;
  border-color: ${PRIMARY_COLOR};
  background-color: transparent;
  &:hover{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: ${BLACK_COLOR} !important;
    fill: ${BLACK_COLOR};
  }
  &:focus{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: transparent !important;
    color: ${PRIMARY_COLOR} !important;
    box-shadow: 0 0 0 0.2rem ${GRAY_COLOR};
  }
  &:active{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: black !important;
  }
  &:after{
    padding-left: 3rem;
    content: "";
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none"> <path d="M0.95 15.0001L0.25 14.4838L0.249999 0.49131L0.924999 6.10057e-05L7.75 7.03381L7.75 8.06756L0.95 15.0001Z" fill="${(props) => props.shouldHover ? BLACK_COLOR.replace("#", "%23") : PRIMARY_COLOR.replace("#", "%23")}" /> </svg>') center center no-repeat;
  }
`;

const PageButton : React.FunctionComponent = (props) => {
  const [shouldHover, setShouldHover] = useState(false);
  return <StyledPageButton shouldHover={shouldHover} onMouseEnter={() => setShouldHover(true)} onMouseLeave={() => setShouldHover(false)}>{props.children}</StyledPageButton>
}

const StyledProjectLinkNext = styled(BSButton)`
color: ${PRIMARY_COLOR};
font-family: ${PRIMARY_FONT};
border: none;
background-color: transparent;
&:hover{
  color: ${WHITE_COLOR} !important;
  fill: ${BLACK_COLOR};
  text-decoration: none !important;
  background: none !important;
  border: none !important;
  }
&:focus{
  color: ${PRIMARY_COLOR};
  fill: ${BLACK_COLOR};
  text-decoration: none;
  background: none;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  }
&:active{
  color: ${PRIMARY_COLOR};
  fill: ${BLACK_COLOR};
  text-decoration: none;
  background: transparent !important;
  border: none;
  }
&:after{
  padding-left: 3rem;
  padding-top: 0rem;
  content: "";
  background: url('data:image/svg+xml;utf8,<svg width="6" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.95 15L0.25 14.4838L0.249999 0.491264L0.924999 1.52293e-05L7.75 7.03376L7.75 8.06752L0.95 15Z" fill="${(props) => props.shouldHover ? WHITE_COLOR.replace("#", "%23") : PRIMARY_COLOR.replace("#", "%23")}" /> </svg>') center center no-repeat;
  }
`

const StyledProjectLinkPrevious = styled(BSButton)`
color: ${PRIMARY_COLOR};
font-family: ${PRIMARY_FONT};
border: none;
background-color: transparent;
&:hover{
  color: ${WHITE_COLOR} !important;
  fill: ${BLACK_COLOR};
  text-decoration: none !important;
  background: none !important;
  border: none !important;
  }
&:focus{
  color: ${PRIMARY_COLOR};
  fill: ${BLACK_COLOR};
  text-decoration: none;
  background: none;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  }
&:active{
  color: ${PRIMARY_COLOR};
  fill: ${BLACK_COLOR};
  text-decoration: none;
  background: transparent !important;
  border: none;
  }
&:before{
  padding-left: 3rem;
  padding-top: 0rem;
  content: "";
  background: url('data:image/svg+xml;utf8,<svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.44 0.5L6 0.913L6 12.107L5.46 12.5L-2.78573e-07 6.873L-2.42423e-07 6.046L5.44 0.5Z" fill="${(props) => props.shouldHover ? WHITE_COLOR.replace("#", "%23") : PRIMARY_COLOR.replace("#", "%23")}" /> </svg>') center center no-repeat;
  }
`

const ProjectLink: React.FunctionComponent<{ to: string, next: boolean }> = ({ to, next, children }) => {
  const [shouldHover, setShouldHover] = useState(false);
  const history = useHistory();
  const handleClick = () => {
    history.push(to)
  }
  return next ? <StyledProjectLinkNext shouldHover={shouldHover} onClick={handleClick} onMouseEnter={() => setShouldHover(true)} onMouseLeave={() => setShouldHover(false)}>{children}</StyledProjectLinkNext> : <StyledProjectLinkPrevious shouldHover={shouldHover} onClick={handleClick} onMouseEnter={() => setShouldHover(true)} onMouseLeave={() => setShouldHover(false)}>{children}</StyledProjectLinkPrevious>
}

const PageDivider = styled.hr`
  margin-top: 1rem;
  border-top: 1px solid ${SECONDARY_COLOR};
  margin-bottom: 1rem;
`;

export {
  ProjectContent,
  ProjectMainTitle,
  ProjectDownArrow,
  BannerTrapezium,
  BannerDescContent,
  BannerTitle,
  BannerSubTitle,
  BannerDate,
  BannerTechStack,
  BannerDescription,
  BannerDescBox,
  BannerDivider,
  BannerButton,
  BannerImage,
  CardTrapezium,
  CardContent,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardDivider,
  CardTechStack,
  PageContent,
  PageImage,
  PageTitle,
  PageSubtitle,
  PageTechStack,
  PageDate,
  PageDescBox,
  PageDescription,
  PageShortDescription,
  PageParagraph,
  PageButton,
  PageDivider,
  ProjectLink
}
