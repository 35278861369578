import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from "./pages/Home";
import Project from "./pages/Project";
import AboutMe from "./pages/AboutMe";
import Contact from "./pages/Contact";
import DefaultLayout from "./layout/DefaultLayout";

function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <DefaultLayout>
        <Switch>
          <Route path="/project/:id" component={Project} />
          <Route path="/about">
            <AboutMe />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/users">
            <Home />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </DefaultLayout>
    </Router>
  );
}

export default App;
