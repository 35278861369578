import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR, BLACK_COLOR, GRAY_COLOR, WHITE_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "appconstants";
import Image from "react-bootstrap/Image";
import BSButton from "react-bootstrap/Button";
import { Form } from "react-bootstrap";

const ContactContent = styled.section`
  padding-top: 10rem;
  padding-bottom: 9rem;
`;

const ContactTitle = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: 48px;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
`;

const ContactInfo = styled.h3`
  white-space: pre-line;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  color: ${WHITE_COLOR};
`;

const ContactFormGroup = styled(Form.Group)`
`
const ContactFormLabel = styled(Form.Label)`
  font-family: ${PRIMARY_FONT};
  font-size: 16px;
  color: ${WHITE_COLOR};
`;

const ContactFormField = styled(Form.Control)`
  background: ${BLACK_COLOR};
  border: 1px solid ${SECONDARY_COLOR} !important;
  border-radius: 0px !important;
  color: ${SECONDARY_COLOR};
  &:focus {
    background: ${BLACK_COLOR};
    color: ${SECONDARY_COLOR};
  }
`;

const ContactFormButton = styled(BSButton)`
  margin-top: 15px;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  font-weight: 700;
  color: ${PRIMARY_COLOR};
  text-align: center;
  padding: 10px 28px;
  border-radius: 0;
  border-color: ${PRIMARY_COLOR};
  background-color: transparent;
  &:hover{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: ${BLACK_COLOR} !important;
  }
  &:focus{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: transparent !important;
    color: ${PRIMARY_COLOR} !important;
    box-shadow: 0 0 0 0.2rem ${GRAY_COLOR};
  }
  &:active{
    border-color: ${PRIMARY_COLOR} !important;
    background-color: ${PRIMARY_COLOR} !important;
    color: black !important;
  }
`;

const ContactThankYouNote = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: 24px;
  text-align: center;
  color: ${WHITE_COLOR};
`

export {
  ContactContent,
  ContactTitle,
  ContactInfo,
  ContactFormGroup,
  ContactFormLabel,
  ContactFormField,
  ContactFormButton,
  ContactThankYouNote
}