// Colors
export const GRAY_COLOR = "#393939"
export const WHITE_COLOR = "#FFFFFF"
export const BLACK_COLOR = "#1A1A1A"
export const TURQUOISE_COLOR = "#84D6D1"
export const ORANGE_COLOR = "#FFC062"

export const HEADER_TEXT_COLOR = WHITE_COLOR
export const PRIMARY_FONT = "Goldman Regular"
export const SECONDARY_FONT = "Proxima Nova Regular"
export const PRIMARY_COLOR = ORANGE_COLOR
export const SECONDARY_COLOR = TURQUOISE_COLOR
// export const PRIMARY_FONT = "'Rosario', sans-serif"
// export const SECONDARY_FONT = "'Open Sans', sans-serif"