import {
    ContactContent,
    ContactFormLabel,
    ContactTitle,
    ContactFormButton,
    ContactFormField,
    ContactInfo,
    ContactFormGroup,
    ContactThankYouNote
} from "components/ContactFields";
import { Container, Row, Col, Form } from "react-bootstrap";
import { personalData } from "appData";
import { Link } from "react-router-dom";
import { ReactComponent as MailLogo } from "assets/contact/mailLogo.svg";
import { ReactComponent as PhoneLogo } from "assets/contact/phoneLogo.svg";
import React, { useState } from 'react';
const fetch = require('node-fetch');

const Contact: React.FC = () => {
    const pd = personalData
    const img = require(`../${pd.iconurl}`).default
    // type InputType = {
    //     firstName: string;
    //     lastName: string;
    //     emailAddress: string;
    //     subject: string;
    //     message: string; 
    // }
    const [inputField, setInputField] = useState({firstName: '', lastName: '', emailAddress: '', subject: '', message: ''});
    const [validated, setValidated] = useState(false)
    const [submitted, setSubmitted] = useState(false)


    const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputField({...inputField, [e.currentTarget.name]: e.currentTarget.value})
    }
    const textAreaHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputField({...inputField, [e.currentTarget.name]: e.currentTarget.value})
    }
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else { // validated
            const {firstName, lastName, emailAddress, subject, message} = inputField
            const emailSubject = `Message from ${firstName} ${lastName} (${emailAddress}): ${subject}`
            const qparams = {subject: emailSubject, message: message}
            const host = process.env.REACT_APP_API_URI
            const route = "/contactme" 
            var url = new URL(host+route)
            url.search = new URLSearchParams(qparams).toString()
            fetch(url)
            setSubmitted(true)
        }
        setValidated(true);
   }

    const renderForm = () => (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <ContactFormGroup controlId="contactForm.FirstName">
                        <ContactFormLabel>First Name*</ContactFormLabel>
                        <ContactFormField required type="text" name="firstName" onChange={inputHandler} />
                    </ContactFormGroup>
                </Col>
                <Col xs={6}>
                    <ContactFormGroup controlId="contactForm.LastName">
                        <ContactFormLabel>Last Name*</ContactFormLabel>
                        <ContactFormField required type="text" name="lastName" onChange={inputHandler} />
                    </ContactFormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ContactFormGroup controlId="contactForm.Email">
                        <ContactFormLabel>Email Address*</ContactFormLabel>
                        <ContactFormField required type="email" name="emailAddress" onChange={inputHandler} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                    </ContactFormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ContactFormGroup controlId="contactForm.Subject">
                        <ContactFormLabel>Subject*</ContactFormLabel>
                        <ContactFormField required type="text" name="subject" onChange={inputHandler} />
                    </ContactFormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ContactFormGroup controlId="contactForm.Message">
                        <ContactFormLabel>Message*</ContactFormLabel>
                        <ContactFormField required as="textarea" rows={6}
                            style={{ minWidth: "100%", borderRadius: "5px" }}
                            name="message" onChange={textAreaHandler}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide your message.
                        </Form.Control.Feedback>
                    </ContactFormGroup>
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: "right" }}>
                    <ContactFormButton type="submit">Submit</ContactFormButton>
                </Col>
            </Row>
        </Form>
    )
    const renderThankYouNote = () => (
        <Row style={{marginBottom: "21.8rem"}}>
            <Col>
                <ContactThankYouNote>Thanks for reaching out!</ContactThankYouNote>
                <ContactThankYouNote>I'll be in touch with you shortly.</ContactThankYouNote>
            </Col>
        </Row>
    )
    return (
        <ContactContent id="contact">
            <Container>
                <Row>
                    <Col xs={5}>
                        <ContactTitle>Contact</ContactTitle>
                        <ContactInfo><MailLogo style={{}} />&nbsp; kinchang0811@gmail.com</ContactInfo>
                        <ContactInfo><PhoneLogo />&nbsp; (510) 458-3511</ContactInfo>
                    </Col>
                    <Col>
                        {/* { renderThankYouNote() } */}
                        {submitted ? renderThankYouNote() : renderForm()}
                    </Col>
                </Row>
            </Container>
        </ContactContent >
    )
}

export default Contact;
