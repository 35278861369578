import {
    AboutMeContent,
    AboutMeDescContent,
    AboutMeTitle,
    AboutMeDescription,
    AboutMeImage,
    ResumeTitle,
    ResumeButton
} from "components/AboutMeFields";
import { Container, Row, Col } from "react-bootstrap";
import { personalData } from "appData";
import { Link } from "react-router-dom";

const AboutMe: React.FC = () => {
    const pd = personalData
    const img = require(`../${pd.iconurl}`).default
    return (
        <AboutMeContent id="about">
            <Container>
                <Row>
                    <Col xs={2} />
                    <Col>
                        <Row>
                            <Col>
                                <AboutMeTitle>About Me</AboutMeTitle>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "1rem"}}>
                            <Col>
                                <AboutMeImage src={img} fluid />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AboutMeDescContent>
                                    <AboutMeDescription>{pd.introduction}</AboutMeDescription>
                                </AboutMeDescContent>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "1rem"}}>
                            <Col className="d-flex justify-content-center">
                                <Link to="/files/resume.pdf" target="_blank" download>
                                    <ResumeButton>
                                        Software Engineer Resume
                                    </ResumeButton>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} />
                </Row>
            </Container>
        </AboutMeContent >
    )
}

export default AboutMe;
